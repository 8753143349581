import React from 'react'
import { graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Hero from '../components/hero'
import Quiz from '../components/quiz'
import RelatedArticles from '../components/relatedArticles'
import BottomNavigation from '../components/layout/bottomNavigation'
import { getCurrentUrl } from '../utils/helpers'

export default function QuizPage({ data, pageContext, location }) {
    const { contentfulQuiz } = data

    const quizQuestions = JSON.parse(
        contentfulQuiz.childContentfulQuizQuestionsJsonNode.internal.content
    ).quiz

    const { next, previous } = pageContext

    return (
        <>
            <GatsbySeo
                title={`Quiz - ${contentfulQuiz.title}`}
                description={contentfulQuiz.description}
                openGraph={{
                    title: `Quiz - ${contentfulQuiz.title}`,
                    description: contentfulQuiz.subtitle,
                    url: getCurrentUrl(),
                }}
            />
            <Hero
                heading={contentfulQuiz.title}
                body={contentfulQuiz.description}
            />
            <Quiz
                fullDesign={true}
                questions={quizQuestions}
                title={contentfulQuiz.title}
                difficulty={contentfulQuiz.difficulty}
                contentfulId={contentfulQuiz.contentful_id}
            />
            <BottomNavigation title={'quiz'} previous={previous} next={next} />
            <RelatedArticles headline="Utvalda artiklar" />
        </>
    )
}

export const pageQuery = graphql`
    query ($title: String!) {
        contentfulQuiz(title: { eq: $title }) {
            contentful_id
            title
            difficulty
            description
            topics
            childContentfulQuizQuestionsJsonNode {
                internal {
                    content
                }
            }
        }
    }
`
